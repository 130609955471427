<template>
  <div class="order">
    <el-row style="margin-bottom: 10px">
      <el-col :span="6">
        分类：<el-select
          v-model="formData.task_status"
          placeholder="请选择"
          @change="change"
        >
          <el-option
            :label="'待分配'"
            :value="3"
            v-if="userInfo.level == 1 || userInfo.level == 2"
          >
            待分配
          </el-option>
          <el-option :label="'进行中'" :value="2"> 进行中 </el-option>
          <el-option :label="'已完成'" :value="1"> 已完成 </el-option>
          <el-option :label="'失败'" :value="0"> 失败 </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" style="display: flex; align-items: center">
        日期：<el-date-picker
          v-model="formData.select_date"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">查询</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 30px" v-if="formData.level == 1">
      <el-col :span="6" style="display: flex; align-items: center">
        组长：<el-select
          v-model="formData.group_admin_id"
          placeholder="请选择"
          @visible-change="visibleChange"
          @change="search"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in groupAdmin"
            :key="index"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </el-col>
      <el-col style="margin: 0 20px 0 20px" :span="5">
        <el-input
          placeholder="请输入客户的联系账号或昵称"
          type="text"
          v-model="searchVal"
        ></el-input>
      </el-col>
    </el-row>
    <div
      style="
        background: #f6f6f6;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: end;
      "
    >
      <el-button
        v-if="userInfo.level != 3"
        size="mini"
        type="primary"
        @click="operation()"
        >操作</el-button
      >
      <el-button
        v-if="formData.level == 1 && formData.task_status == 3"
        size="mini"
        type="danger"
        @click="handleDelete()"
        >删除</el-button
      >
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="序号" width="70">
        <template slot-scope="scope"> {{ scope.row.index }} </template>
      </el-table-column>
      <el-table-column prop="kh_name" label="姓名"> </el-table-column>
      <el-table-column prop="tel" label="电话"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column prop="date" label="预约时间"> </el-table-column>
      <el-table-column prop="hd" label="预约办理活动"> </el-table-column>
      <el-table-column prop="lp" label="礼品要求"> </el-table-column>
      <el-table-column prop="user_3" label="营销员"> </el-table-column>
      <el-table-column prop="user_4" label="线下用户"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="详情" :visible.sync="dialogVisible" width="80%">
      <div>
        <el-form ref="form" :model="myData" label-width="190px" :rules="rules">
          <el-row>
            <el-col :span="11">
              <el-form-item label="营销员" prop="name">
                <el-input disabled type="text" v-model="myData.name" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="工单分类" prop="kind">
                <el-select
                  v-model="myData.kind"
                  placeholder="请选择"
                  style="width: 100%"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                >
                  <el-option label="移动" :value="1"> 移动 </el-option>
                  <el-option label="联通" :value="2"> 联通 </el-option>
                  <el-option label="电信" :value="3"> 电信 </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="预约时间" prop="date">
                <el-date-picker
                  style="width: 100%"
                  v-model="myData.date"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :disabled="status == 3 ? true : false"
                >
                </el-date-picker>
                <el-button type="primary" size="mini" @click="upData"
                  >更新</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="预期办理活动" prop="hd">
                <el-input
                  type="text"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.hd"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="11">
              <el-form-item label="礼品要求" prop="lp">
                <el-input
                  type="text"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.lp"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="工单地址" prop="address">
                <el-input
                  type="text"
                  v-model="myData.address"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="联系方式" prop="contact">
                <el-input
                  type="text"
                  v-model="myData.contact"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="宽带安装地址">
                <el-input
                  type="text"
                  v-model="myData.kd_address"
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="失败原因">
                <el-input type="text" v-model="myData.remark" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="新上号码">
                <el-input type="text" v-model="myData.new_num" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="是否是本人身份证办理号码" prop="oneself">
                <el-radio-group
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.oneself"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="证件是否齐全" prop="is_zj">
                <el-radio-group
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.is_zj"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="11">
              <el-form-item label="是否达量限速客户" prop="is_xs">
                <el-radio-group
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.is_xs"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="2">未知</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="是否需要宽带" prop="is_kd">
                <el-radio-group
                  :disabled="userInfo.level != 1 || status == 3 ? true : false"
                  v-model="myData.is_kd"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="2">未知</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="display: flex" v-if="formData.level == 2">
            <el-col :span="11">
              <el-form-item label="串码">
                <el-input
                  type="text"
                  :placeholder="!myData.code ? '暂无' : myData.code"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 10px" v-if="!myData.code">
              <el-button type="primary" size="mini" @click="getCode('1')"
                >明星获取</el-button
              >
              <el-button type="primary" size="mini" @click="getCode('0')"
                >普通获取</el-button
              >
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="16">
              <el-form-item label="备注">
                <ul class="desc">
                  <li v-for="(item, index) in myData.desc" :key="index">
                    <p class="t">
                      <span style="margin-right: 10px">{{
                        item.user_name
                      }}</span>
                      <span>{{ item.create_date }}</span>
                    </p>
                    <p class="b">
                      {{ item.comment }}
                    </p>
                  </li>
                </ul>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="mini" @click="descdialog = true"
                  >添加备注</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="userInfo.level == 1"
          type="primary"
          :loading="loading"
          @click="submit('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="添加备注" :visible.sync="descdialog" width="50%">
      <div>
        <el-input
          type="textarea"
          placeholder="请输入备注"
          :row="2"
          v-model="desc"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="descdialog = false">取 消</el-button>
        <el-button type="primary" @click="Closedescdialog">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作" :visible.sync="operationVisible" width="80%">
      <el-form
        ref="operationform"
        :model="operationData"
        label-width="190px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="operationData.status"
                placeholder="请选择"
                style="width: 100%"
                @change="changeStatus"
              >
                <el-option label="进行中" :value="2"> 进行中 </el-option>
                <el-option label="已完成" :value="1" v-if="formData.level != 2">
                  已完成
                </el-option>
                <el-option label="失败" :value="0" v-if="formData.level != 2">
                  失败
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="11"
            v-if="operationData.status == 2 || operationData.status == '进行中'"
          >
            <el-form-item label="等级" prop="level">
              <el-select
                v-model="operationData.level"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="小组主管" :value="2"> 小组主管 </el-option>
                <el-option label="线上销售人员" :value="3">
                  线上销售人员
                </el-option>
                <el-option label="线下办单人员" :value="4">
                  线下办单人员
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="11"
            v-if="operationData.status == 2 || operationData.status == '进行中'"
          >
            <el-form-item label="用户" prop="user">
              <el-select
                v-model="operationData.name"
                placeholder="请选择"
                style="width: 100%"
                @focus="focusUser"
                clearable
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in userOptions"
                  :key="index"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="11"
            v-if="operationData.status == 1 || operationData.status == '已完成'"
          >
            <el-form-item label="礼品" prop="gift" v-if="userInfo.level == 4">
              <el-select
                v-model="operationData.gift_id"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in giftOptions"
                  :key="index"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11" v-if="operationData.status == 0">
            <el-form-item label="失败原因" prop="remark">
              <el-input
                type="textarea"
                :disabled="operationData.status == 0 ? false : true"
                v-model="operationData.remark"
              />
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="operationData.status == 1">
            <el-form-item label="新上号码" prop="new_num">
              <el-input
                type="text"
                :disabled="operationData.status == 1 ? false : true"
                v-model="operationData.new_num"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operationVisible = false">取 消</el-button>
        <el-button type="primary" :loading="operLoading" @click="closeOperation"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        task_status:
          JSON.parse(window.localStorage.getItem("user")).level == 1 ||
          JSON.parse(window.localStorage.getItem("user")).level == 2
            ? 3
            : 2,
        level: JSON.parse(window.localStorage.getItem("user")).level,
        id: JSON.parse(window.localStorage.getItem("user")).id,
        select_date: "",
        group_admin_id: "",
      },
      myData: {
        name: "", //营销员
        kind: "", //工单分类
        date: "", //预约时间
        hd: "", //预期办理活动
        lp: "", //礼品要求
        address: "", //工单地址
        contact: "", //联系方式
        new_num: "", //新上号码
        oneself: "", //是否是本人身份证办理号码
        is_zj: "", //证件是否齐全
        is_xs: "", //是否达量限速客户
        is_kd: "", //是否需要宽带
        kd_address: "", //宽带安装地址
        remark: "", //失败原因
        code: "", //串码
        desc: "", //desc
        status: "", //状态
        level: "", //等级
        user: "", //用户
        gift: "", //礼品
      },
      operationData: {
        gift_id: "", //礼品
        name: "",
        id: [],
        status: 1, //状态
        remark: "", //失败原因,
        user_id: JSON.parse(window.localStorage.getItem("user")).id,
        user_level: JSON.parse(window.localStorage.getItem("user")).level,
        new_num: "", //新上号码
      },
      content: "", // 添加备注
      dialogVisible: false,
      tableData: [],
      searchVal: "",
      options: [],
      allOptions: [
        [
          {
            value: 3,
            label: "待分配",
          },
          {
            value: 2,
            label: "进行中",
          },
          {
            value: 1,
            label: "已完成",
          },
          {
            value: 0,
            label: "失败",
          },
        ],
        [
          {
            value: 2,
            label: "进行中",
          },
          {
            value: 1,
            label: "已完成",
          },
          {
            value: 0,
            label: "失败",
          },
        ],
      ],
      rules: {
        name: [{ required: true, message: "请输入营销员", trigger: "blur" }],
        kind: [{ required: true, message: "请选择工单分类", trigger: "blur" }],
        date: [{ required: true, message: "请选择预约时间", trigger: "blur" }],
        hd: [
          { required: true, message: "请输入预期办理活动", trigger: "blur" },
        ],
        lp: [{ required: true, message: "请输入礼品要求", trigger: "blur" }],
        address: [
          { required: true, message: "请输入工单地址", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        oneself: [
          {
            required: true,
            message: "请选择是否是本人身份证办理号码",
            trigger: "blur",
          },
        ],
        is_zj: [
          { required: true, message: "请选择证件是否齐全", trigger: "blur" },
        ],
        is_xs: [
          {
            required: true,
            message: "请选择是否达量限速客户",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          },
        ],
        level: [
          {
            required: true,
            message: "请选择等级",
            trigger: "blur",
          },
        ],
        user: [
          {
            required: true,
            message: "请选择用户",
            trigger: "blur",
          },
        ],
        new_num: [
          {
            required: true,
            message: "请输入新上号码",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入失败原因",
            trigger: "blur",
          },
        ],
        gift: [
          {
            required: true,
            message: "请选择礼品",
            trigger: "blur",
          },
        ],
      },
      userOptions: [],
      giftOptions: [],
      descdialog: false,
      desc: "", //添加备注
      userInfo: JSON.parse(window.localStorage.getItem("user")),
      loading: false,
      operLoading: false,
      task_id: "",
      status: "",
      operationVisible: false,
      groupAdmin: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    if (this.level == 2 || this.level == 1) {
      this.options = this.allOptions[0];
    } else {
      this.options = this.allOptions[1];
    }

    this.getData();
  },

  methods: {
    // 多选
    handleSelectionChange(val) {
      this.operationData.id = [];
      val.map((item) => {
        this.operationData.id.push(item.id);
      });
    },
    // 时间更新
    async upData() {
      let data = {
        date: this.myData.date,
        task_id: this.task_id,
        user_id: this.userInfo.id,
        user_name: this.userInfo.name,
      };
      let response = await this.$request.post("/updateTaskDate", data);
      if (response.data.success) {
        this.$message({
          message: "更新成功",
          type: "success",
        });
      } else {
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    // 操作
    operation() {
      this.operationVisible = true;
      // this.operationData.id.push(row.id);
      if (this.userInfo.level == 4) this.getGift();
    },
    async closeOperation() {
      this.operLoading = true;
      let response = await this.$request.post(
        "/updateTask",
        this.operationData
      );
      if (response.data.success) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        this.$message({
          message: response.data.msg,
          type: "erroe",
        });
      }
      this.operLoading = false;
      this.operationVisible = false;
      this.getData();
    },
    async getData() {
      let _this = this;
      let response = await this.$request.post("/getAllTask", this.formData);
      if (!this.searchVal) {
        this.tableData = response.data.res;
        this.tableData.map((item, index) => {
          item.index = index + 1;
          if (item.kind == "移动") {
            item.kind = 1;
          } else if (item.kind == "联通") {
            item.kind = 2;
          } else if (item.kind == "电信") {
            item.kind = 3;
          }
        });
      } else {
        this.tableData = [];
        response.data.res.filter(function (pro, index) {
          pro.index = index + 1;
          Object.keys(pro).some(function (key) {
            if (String(pro[key]).toLowerCase().indexOf(_this.searchVal) > -1) {
              _this.tableData.push(pro);
            }
          });
        });
      }
    },
    change(e) {
      this.formData.task_status = e;
      this.getData();
    },
    search() {
      this.getData();
    },
    async handleEdit(row) {
      this.task_id = row.id;
      this.status = row.flag;
      let response = await this.$request.post("/getTask", { id: row.id });
      this.myData = response.data.res;
      let num = ["否", "是", "未知"];
      num.map((item, index) => {
        for (let key in this.myData) {
          if (this.myData[key] == item) {
            this.myData[key] = index;
          }
        }
      });
      if (this.operationData.id.length > 1) {
        this.$message({
          message: "查看详情只能选择一条工单",
          type: "error",
        });
      } else {
        this.dialogVisible = true;
      }
    },
    // 礼品
    async getGift() {
      let _this = this;
      let response = await this.$request.post("/getMyGift", {
        user_id: this.userInfo.id,
      });
      response.data.res.map((item) => {
        let obj = {
          label: item.name + "(" + item.residue + "/" + item.count + ")",
          value: item.id,
        };
        _this.giftOptions.push(obj);
      });
      _this.giftOptions.unshift({
        label: "打折或其他",
        value: "-1",
      });
    },
    handleDelete() {
      this.$alert("确定删除这条工单吗？", "提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action == "confirm") {
            let response = await this.$request.post("/delTask", {
              ids: this.operationData.id,
            });
            if (response.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getData();
            } else {
              this.$message({
                message: response.data.res,
                type: "success",
              });
            }
          }
        },
      });
    },
    // 添加备注
    async Closedescdialog() {
      let _this = this;
      if (!this.desc) {
        this.$message({
          message: "请输入备注",
          type: "warning",
        });
      } else {
        let data = {
          comment: _this.desc,
          task_id: _this.task_id,
          user_id: _this.userInfo.id,
          user_name: _this.userInfo.name,
        };
        let response = await _this.$request.post("/addComment", data);
        if (response.data.success) {
          this.$message({
            message: "添加备注成功",
            type: "success",
          });
          _this.dialogVisible = false;
          _this.descdialog = false;
        } else {
          this.$message({
            message: "添加备注失败",
            type: "error",
          });
        }
      }
    },
    // 获取串码
    async getCode(id) {
      let _this = this;
      let response = await _this.$request.post("/getCode", {
        is_super: id,
        task_id: _this.task_id,
      });
      if (response.data.success) {
        this.$message({
          message: "获取成功",
          type: "success",
        });
        _this.myData.code = response.data.res;
      } else {
        this.$message({
          message: response.data.msg,
          type: "warning",
        });
      }
    },
    async focusUser(e) {
      if (!this.operationData.level) {
        this.$message({
          message: "请先选择等级",
          type: "warning",
        });
      } else {
        let data = {
          level: this.operationData.level,
          user_id: this.userInfo.id,
          user_level: this.userInfo.level,
        };
        let response = await this.$request.post("/getUser", data);
        this.userOptions = response.data.res;
      }
    },
    // 保存修改
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.myData.user_id = this.userInfo.id;
          this.myData.user_name = this.userInfo.name;
          this.myData.task_id = this.task_id;
          this.loading = true;
          if (this.myData.status == "失败") {
            this.myData.status = 0;
          } else if (this.myData.status == "已完成") {
            this.myData.status = 1;
          } else if (this.myData.status == "进行中") {
            this.myData.status = 2;
          } else if (this.myData.status == "待分配") {
            this.myData.status = 3;
          }
          let data = {
            user_id: this.userInfo.id,
            user_name: this.userInfo.name,
            task_id: this.task_id,
            status: this.myData.status,
            level: this.myData.level,
            user: this.myData.user,
            old_status: 3,
          };
          let formData = {};
          if (this.status == 3) {
            formData = data;
          } else {
            formData = this.myData;
          }
          let response = await this.$request.post(
            "/webUpdateTaskInfo",
            formData
          );
          if (response.data.success) {
            this.loading = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getData();
          } else {
            this.$message({
              message: "保存失败",
              type: "success",
            });
            this.dialogVisible = false;
          }
        } else {
          this.$message({
            message: "请填写必选项",
            type: "warning",
          });
          return false;
        }
      });
    },
    // 切换状态
    changeStatus(e) {
      if (e != 0) {
        this.operationData.remark = "";
      }
    },
    async visibleChange(val) {
      if (val) {
        let data = {
          level: 2,
          user_id: this.userInfo.id,
          user_level: this.userInfo.level,
        };
        let response = await this.$request.post("/getUser", data);
        this.groupAdmin = response.data.res;
      }
    },
  },
};
</script>
<style lang='less' scoped>
.desc {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 20px;
  p {
    margin: 0;
    padding: 0;
    line-height: 24px;
  }
  .t {
    display: flex;
    align-items: center;
    color: #9c9c9c;
    &::before {
      display: inline-block;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000;
      margin-right: 10px;
    }
  }
  .b {
    font-size: 16px;
    margin-left: 20px;
  }
}
/deep/.el-form-item__content {
  display: flex;
}
</style>